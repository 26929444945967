<template>
  <div class="flex-column circleQgl_main">
    <pageHead class="messageMainView_head" title="圈子管理" :pageHead_fx="false" />
    <div class="main">
      <van-form>
        <van-cell-group>
          <CircleMemberCell
            @goDeleteMember="goDeleteMember"
            @goAddMember="goAddMember"
            :circle-detail="circleDetail"
            :list="memberList"></CircleMemberCell>
        </van-cell-group>

        <van-cell-group v-if="circleDetail.identity === 1" inset style="margin-top: 8px;">
          <van-cell title="圈子名称" @click="changeTitle" :value="circleDetail.title" is-link />
          <van-cell title="圈子简介" @click="changeIntroduction" :label="circleDetail.introduction">
            <template #label>
              <div class="six-line" v-html="getBrIntroduction(circleDetail.introduction)"></div>
            </template>
          </van-cell>
          <van-cell title="圈子头像" is-link @click="changeAvatar">
            <template #default>
              <div class="flex-row-end-h-center default-header"
                :style="{backgroundColor: `#${circleDetail.background}`}">
                <img v-if="circleDetail.avatar" :src="circleDetail.avatar" alt="" />
              </div>
            </template>
          </van-cell>
          <van-cell title="圈子分类" @click.prevent="van_type" :value="circleDetail.circleTypeTitle" is-link />
          <van-cell title="圈子背景图" is-link @click.prevent="van_Bjt ">
            <template #default>
              <div class="flex-row-end-h-center default-bg">
                <span>{{ circleDetail.backgroundName }}</span>
                <span class="header-box" :style="{backgroundColor: circleDetail.background}"></span>
              </div>
            </template>
          </van-cell>
        </van-cell-group>

        <van-popup v-model="qzBjtShow" position="bottom">
          <CircleTypePicker @cancelBg="cancelBg" @confirmBg="handleConfirmBg"></CircleTypePicker>
        </van-popup>

        <van-cell-group v-if="circleDetail.identity === 1" inset style="margin-top: 8px;">
          <van-cell @click.prevent="van_glzr" title="圈主管理权转让" is-link />
          <van-cell @click.prevent="van_qzglysz" title="圈子管理员设置" is-link />
        </van-cell-group>

        <van-cell-group v-if="circleDetail.identity === 1" inset style="margin-top: 8px;">
          <van-switch-cell size="20px" inactive-color="rgba(0,0,0,0.26)" active-color="#00A870"
            @change="handleChangePublic" v-model="isPublicPrivate" title="设为私密圈" />
          <van-switch-cell size="20px" :disabled="isPublicPrivate" inactive-color="rgba(0,0,0,0.26)"
            active-color="#00A870"
            @change="handleChangeApply" v-model="isApplySwitch" title="成员加入圈子申请" />
        </van-cell-group>

        <div class="button_cre" v-if="circleDetail.identity === 1">
          <van-button block style="color:#F42626;margin:8px 0 9px 0;" @click="dismissCircle()">
            解散圈子
          </van-button>
          <p class="button_line"></p>
        </div>
      </van-form>
      <!--todo  修改圈子名称，简介-->
      <van-dialog
        width="80%"
        class="change-text-dialog"
        v-model="isShowChangeText"
        :title="changeDialogTitle"
        :show-confirm-button="false"
        :show-cancel-button="false">
        <template #title>
          <div class="flex-row-space-between-hCenter change-text-title">
            <span>{{ changeDialogTitle }}</span>
            <img @click="handleCloseText" src="/images/common/clear.png" alt="">
          </div>
        </template>
        <template #default>
          <van-field
            :border="true"
            autosize
            rows="3"
            type="textarea"
            :formatter="vanFieldFormatter"
            :maxlength="changeDialogLimit"
            v-model="changeDialogValue"
            placeholder="请输入内容" />
          <div class="flex-row-space-between-hCenter submit-btn">
            <span class="word-number">{{ changeDialogValue.length }}/{{ changeDialogLimit }}</span>
            <van-button round color="#FE5800" @click="handleConfirmChangeText" size="mini" type="primary">提交
            </van-button>
          </div>
        </template>
      </van-dialog>
      <!--todo 修改圈子头像-->
      <van-action-sheet
        v-model="showAction"
        :actions="actions"
        cancel-text="取消"
        close-on-click-action
        @select="handleSelect"
      />
      <!--todo 修改圈子分类-->
      <van-popup v-model="qzTypeShow" position="bottom">
        <van-picker
          title="圈子类型"
          show-toolbar
          close-on-click-action
          :columns="circleTypeList"
          @confirm="handleConfirmType"
          @cancel="onCancel"
        >
          <div class="circle-bg-item" slot="option" slot-scope="item">
            <span class="color">{{ item.title }}</span>
          </div>
        </van-picker>
      </van-popup>
      <!--todo 修改背景图-->
    </div>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import CircleMemberCell from '@/components/circle/circleMemberCell'
import {selectFile} from "@/utils/selectImg"
import CircleTypePicker from '@/views/app/circle/components/circleTypePicker'
import {
  getCircleDetail,
  getListCircleType, setDismissCircle,
  updateCircleAvatar, updateCircleBackground,
  updateCircleIntroduction,
  updateCircleTitle, updateCircleType, updateJoinApplySwitch, updatePrivateSwitch
} from "@/api/circle";
import {vanFieldFormatter} from '@/utils/utils'
export default {
  name: 'circleQgl', // 圈子基本信息设置
  components: {
    pageHead,
    CircleMemberCell,
    CircleTypePicker
  },
  data () {
    return {
      memberList: [],
      circleTypeList: [], // 圈子类型
      isShowChangeText: false, // 是否修改文本类
      changeDialogType: 'title', // title,
      changeDialogTitle: '圈子名称', // title,
      changeDialogValue: '', // 输入内容,
      changeDialogLimit: 10, // 输入内容字数限制
      circleDetail: {}, // 圈子详情
      filenamed: '',
      fileQzBack: '',
      qzTypeVal: '',
      qzMessage: '',
      showAction: false,
      qzTypeShow: false,
      qzBjtShow: false,
      // 滚动方法
      cuIdx: 1,
      actions: [{name: "拍照"}, {name: "相册上传"}],
      columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
      columnsQzBj: ['杭州', '宁波'],
      isPublicPrivate: false, // 设置为私密圈或公开
      isApplySwitch: false, // 设置圈子申请
      fileList: [
        {url: 'https://img01.yzcdn.cn/vant/leaf.jpg'},
      ],
      values: [
        {
          id: 1,
          title: '名称1',
          isDefault: true,
          picter: 'https://img01.yzcdn.cn/vant/cat.jpeg'
        },
      ],
    }
  },
  created () {
    this.getCircleDetail()
    this.getListCircleType()
  },
  methods: {
    vanFieldFormatter,
    getBrIntroduction (text) {
      if (text) {
        return text.replace(/\n/g, '<br />')
      }
    },
    // 跳转至删除成员页面
    goAddMember () {
      this.$router.push({
        path: '/app/circle/addCircleMember',
        query: {
          circleId: this.circleDetail.id
        }
      })
    },
    // 跳转至删除成员页面
    goDeleteMember () {
      this.$router.push({
        path: '/app/circle/deleteCircleMember',
        query: {
          circleId: this.circleDetail.id
        }
      })
    },
    /**
     * 关闭文本修改的dialog
     */
    handleCloseText () {
      this.isShowChangeText = false
    },
    /**
     * todo 解散圈子
     */
    dismissCircle () {
      this.$dialog.confirm({
        title: '解散圈子',
        message: '解散后无法还原，确定解散 当前圈子吗？',
        confirmButtonText: '解散圈子',
        cancelButtonText: '再考虑下'
      }).then(() => {
        console.log(this.currentOperaItem)
        this.setDismissCircle()
      })
    },
    /**
     * todo 解散圈子
     */
    async setDismissCircle () {
      const id = this.circleDetail.id
      if (id) {
        await setDismissCircle(id)
        if (this.$route.query.from === 'circleDetail') {
          this.$router.go(-2)
        } else {
          this.$router.go(-1)
        }
      }
    },
    /**
     * todo 选择圈子背景
     */
    handleConfirmBg (value) {
      console.log('value11')
      console.log(value)
      this.updateCircleBackground(value)
    },
    /**
     * todo 修改圈子背景
     */
    async updateCircleBackground (value) {
      const data = {
        background: value.value,
        circleId: this.circleDetail.id
      }
      await updateCircleBackground(data)
      this.circleDetail.background = value.value
      this.circleDetail.backgroundName = value.title
      this.qzBjtShow = false
      this.$toast({
        message: '修改成功',
        position: 'top'
      })
    },
    cancelBg () {
      this.qzBjtShow = false
    },
    /**
     * todo 获取圈子类型列表
     */
    async getListCircleType () {
      const res = await getListCircleType() || []
      if (res.length > 0) {
        res.forEach(item => {
          this.circleTypeList.push(item.title)
        })
      }
      this.circleTypeList = res
      console.log('this.circleTypeList')
      console.log(this.circleTypeList)
    },
    /**
     * todo 修改圈子名称
     */
    async updateCircleTitle () {
      try {
        const data = {
          title: this.changeDialogValue,
          circleId: this.circleDetail.id
        }
        await updateCircleTitle(data)
        this.circleDetail.title = this.changeDialogValue
        this.$toast({
          message: '修改成功',
          position: 'top'
        })
        this.isShowChangeText = false
      } catch (e) {
        this.$toast({
          message: e.response.data.msg + '',
          position: 'top '
        })
      }
    },
    /**
     * todo 修改圈子简介
     */
    async updateCircleIntroduction () {
      try {
        const data = {
          introduction: this.changeDialogValue,
          circleId: this.circleDetail.id
        }
        await updateCircleIntroduction(data)
        this.circleDetail.introduction = this.changeDialogValue
        this.$toast({
          message: '修改成功',
          position: 'top'
        })
        this.isShowChangeText = false
      } catch (e) {
        this.$toast({
          message: e.response.data.msg,
          position: 'top'
        })
      }
    },
    /**
     * todo 确认修改名称，简介等
     */
    handleConfirmChangeText () {
      if (!this.changeDialogValue) {
        return;
      }
      if (this.changeDialogType === 'title') {
        this.updateCircleTitle()
        return
      }
      if (this.changeDialogType === 'introduction') {
        this.updateCircleIntroduction()
      }
    },
    /**
     * todo 根据id获取圈子详情
     * @returns {Promise<void>}
     */
    async getCircleDetail () {
      const res = await getCircleDetail(this.$route.query.id) || {}
      // const {data} = res
      this.circleDetail = res
      if (res.backgroundInfo) {
        this.circleDetail.background = res.backgroundInfo.value
        this.circleDetail.backgroundName = res.backgroundInfo.title
      }

      this.isPublicPrivate = !this.circleDetail.isPublic
      this.isApplySwitch = this.circleDetail.isJoinApply

      console.log(res)
      let {leadAndManageMembers} = res || []
      let {normalMembers} = res || []
      if (leadAndManageMembers.length > 0) {
        leadAndManageMembers = leadAndManageMembers.map(item => {
          return {
            ...item,
            avatar: item.userBasicVO.avatar,
            id: item.userBasicVO.userId,
            name: item.userBasicVO.username,
            superAdmin: 0,
            type: 2
          }
        })
      }
      if (normalMembers.length > 0) {
        normalMembers = normalMembers.map(item => {
          return {
            ...item,
            avatar: item.userBasicVO.avatar || '',
            avatarFrameUrl: item.userBasicVO.avatarFrameUrl || '',
            id: item.userBasicVO.userId,
            name: item.userBasicVO.username,
            superAdmin: 0,
            type: 2
          }
        })
      }
      this.memberList = leadAndManageMembers.concat(normalMembers)
      if (this.memberList.length >= 8) {
        this.memberList = this.memberList.slice(0, 8)
      }
    },
    // 确定解散
    onSubmit () {
      this.$dialog.confirm({
        title: '解散圈子',
        confirmButtonText: '确定',
        cancelButtonText: '再考虑下',
        message: '是否确认解散圈子!',
      });
    },
    /**
     * 上传图片
     */
    changeAvatar () {
      this.showAction = true
    },
    /**
     * todo 更新圈子头像
     */
    async updateCircleAvatar (url) {
      const data = {
        avatar: url,
        circleId: this.circleDetail.id
      }
      await updateCircleAvatar(data)
      this.circleDetail.avatar = url
      this.$toast({
        message: '修改成功',
        position: 'top'
      })
    },
    /**
     * 动作面板点击事件
     */
    async handleSelect (action, key) {
      let files = {}
      if (key === 0) {
        files = await selectFile(true);
      } else if (key === 1) {
        files = await selectFile();
      }
      const filesRes = await this.$upload.multipleFilesUpload(files) || []
      if (filesRes.length > 0) {
        console.log(filesRes[0].filename);
        await this.updateCircleAvatar(filesRes[0].filename)
      }
      // this.filenamed = filesRes[0].filename
      // await updateAvatar(filesRes[0].filename, this.userInfo.user_id) // 上传头像
    },
    van_Bjt () {
      this.qzBjtShow = true
    },
    van_type () {
      this.qzTypeShow = true
    },
    selects (v) {
      this.fileQzBack = v.picter
    },
    /**
     * todo 修改类型确定
     */
    handleConfirmType (value) {
      this.updateCircleType(value)
      this.qzTypeVal = value
      this.qzTypeShow = false
    },
    /**
     * todo 更新圈子的类型
     */
    async updateCircleType (value) {
      const data = {
        circleTypeId: value.id,
        circleId: this.circleDetail.id
      }
      await updateCircleType(data)
      this.circleDetail.circleTypeTitle = value.title
      this.$toast({
        message: '修改成功',
        position: 'top'
      })
    },
    // 取消
    onCancel () {
      this.qzTypeShow = false
    },
    // 查看全部成员
    van_all_per () {
      this.$router.push({
        path: '/app/circle/circleManagePerson',
        query: {
          id: this.circleDetail.id
        }
      })
      // this.$router.push({name: "myCreateCircleAllPe"});
    },
    // 滚动
    getSelectValue (value) {
      console.log(value)
    },
    /**
     * todo 修改圈子标题
     */
    changeTitle () {
      this.changeDialogValue = this.circleDetail.title
      this.changeDialogTitle = '圈子名称'
      this.changeDialogType = 'title'
      this.changeDialogLimit = 10
      this.isShowChangeText = true
    },
    /**
     * todo 修改简介
     */
    changeIntroduction () {
      this.changeDialogValue = this.circleDetail.introduction
      this.isShowChangeText = true
      this.changeDialogTitle = '圈子简介'
      this.changeDialogLimit = 100
      this.changeDialogType = 'introduction'
    },
    // 圈子管理转让
    van_glzr () {
      this.$router.push({
        path: "/app/circle/circleZr",
        query: {
          id: this.circleDetail.id
        }
      });
    },
    // 圈子管理员设置
    van_qzglysz () {
      this.$router.push({
        path: "/app/circle/manageSetting",
        query: {
          circleId: this.circleDetail.id
        }
      });
    },
    // 设置为私密圈
    handleChangeApply () {
      this.updateJoinApplySwitch(this.isApplySwitch)
      // if (this.isApplySwitch) {
      //   this.$dialog.confirm({
      //     title: '加入圈子申请',
      //     confirmButtonText: '确认',
      //     cancelButtonText: '取消',
      //     message: '申请加入圈子',
      //   }).then(() => {
      //     if (this.isApplySwitch === this.circleDetail.isJoinApply) {
      //       console.log(1111)
      //     } else {
      //       console.log(2222)
      //     }
      //     this.updateJoinApplySwitch(this.isApplySwitch)
      //   }).catch(() => {
      //     this.isApplySwitch = !this.isApplySwitch
      //   })
      // } else {
      //   this.updateJoinApplySwitch(this.isApplySwitch)
      // }
    },
    /**
     * todo 切换圈子为私密或者公开
     */
    handleChangePublic () {
      if (this.isPublicPrivate) {
        this.$dialog.confirm({
          title: '转私密圈',
          confirmButtonText: '转私密圈',
          cancelButtonText: '再考虑下',
          message: '设为私密圈后，该圈子对外不可见，只可管理员邀请成员加入',
        }).then(() => {
          if (this.isPublicPrivate === this.circleDetail.isPublic) {
            console.log(1111)
          } else {
            console.log(2222)
          }
          this.updatePrivateSwitch(this.isPublicPrivate)
        }).catch(() => {
          this.isPublicPrivate = !this.isPublicPrivate
        })
      } else {
        this.updatePrivateSwitch(this.isPublicPrivate)
      }
    },
    /**
     * todo 修改私密圈开关（圈主）
     */
    async updatePrivateSwitch (value) {
      try {
        const data = {
          privateSwitch: value,
          circleId: this.circleDetail.id
        }
        await updatePrivateSwitch(data)
        this.$toast({
          message: '修改成功',
          position: 'top'
        })
      } catch (e) {
        this.$toast({
          message: e.response.data.msg,
          position: 'top'
        })
        this.isPublicPrivate = !this.isPublicPrivate
      }
    },
    /**
     * todo 修改成员加入申请开关（圈主）
     */
    async updateJoinApplySwitch (value) {
      try {
        const data = {
          joinApplySwitch: value,
          circleId: this.circleDetail.id
        }
        await updateJoinApplySwitch(data)
        this.circleDetail.isJoinApply = value
        this.isApplySwitch = value
        this.$toast({
          message: '修改成功',
          position: 'top'
        })
      } catch (e) {
        this.$toast({
          message: e.response.data.msg,
          position: 'top'
        })
        this.isApplySwitch = !this.isApplySwitch
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .van-cell-group--inset {
    margin: 0;
    border-radius: 0;
  }

  .van-field__control {
    max-height: 200px;
    overflow: auto;
  }
}

.main {
  flex: 1;
  overflow-y: scroll;

  .change-text-dialog.van-dialog {
    padding: 14px 20px;

    .submit-btn {
      margin-top: 16px;

      .word-number {
        font-size: 14px;
        color: rgba(109, 114, 120, 0.50);
      }
    }

    ::v-deep .van-dialog__header {
      padding: 1px 0;
    }

    ::v-deep {
      .van-button {
        padding: 6px 20px;
      }

      .van-dialog__content {
        margin-top: 16px;
      }
    }

    .change-text-title {
      > img {
        padding: 6px 0 6px 6px;
        width: 12px;
        height: 12px;
      }
    }

    .van-cell.van-field {
      padding: 0;

      ::v-deep .van-field__control {
        padding: 12px;
        border: 1px solid rgba(0, 0, 0, 0.21);
        border-radius: 2px;
      }
    }

    .van-dialog__footer {

    }
  }

  .default-header {
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .default-bg {

    .header-box {
      width: 32px;
      height: 32px;
      margin-left: 4px;
      border-radius: 50%;
    }
  }

  ::v-deep {
    .van-cell {
      align-items: center;
    }

    .van-field__word-limit {
      font-size: 14px;
      color: rgba(109, 114, 120, 0.50);
    }

    .van-picker__cancel {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.60);
    }

    .van-picker__title {
      font-size: 16px;
      color: #000000;
    }

    .van-picker__confirm {
      font-size: 16px;
      color: #000000;
    }

    .van-uploader__preview-image {
      width: 46px;
      height: 46px;
      border-radius: 50%;

      img {
        width: 46px;
        height: 46px;
      }
    }

    .van-uploader__upload {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      //  margin-left: 25px;
      .van-uploader__input {
        width: 46px;
        height: 46px;
      }

      .van-icon-photograph::before {
        content: '+';
        top: -4px;
        position: relative;
      }
    }

    .van-field_box2 {
      .van-cell {
        height: 118px;
      }

      input {
        font-size: 14px;
      }

      input::placeholder {
        color: rgba(109, 114, 120, 0.50);
      }
    }

    .van-uploader__preview-delete {
      border-radius: 50%;
    }
  }


  .van_cell_read {
    span {
      font-size: 12px;
      color: #323233;
    }
  }

  .myCreateCircleXzShow {
    ::v-deep {
      .van-dialog__header {
        padding: 0;
      }

      .van-dialog__content {
        justify-content: center;
        text-align: center;
        margin-top: 60px;
      }
    }

    .qzcrxz {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.90);
      margin-top: 12px;
    }

    .show_til {
      width: 166px;
      height: 120px;
      display: inline-block;
      background: url('/images/circle/createNotice.png') no-repeat;
      background-size: 100%;
      position: absolute;
      top: -66px;
      left: 76px;
    }

    .qzcrFdtil {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.40);
      margin-top: 6px;
      margin-bottom: 10px;
    }

    .xzCont {
      width: 280px;
      height: 186px;
      background: #fafafa;
      border-radius: 8px;
      margin: 0 auto;
      font-size: 14px;
      text-align: left;
      padding: 18px 32px;
    }
  }

  ::v-deep {
    // .van-dialog__cancel, .van-dialog__confirm {
    //    flex: 1;
    // }

    //.van-button::before {
    //  width: 149px;
    //  height: 40px;
    //}

    .van-dialog__footer {
      margin: 0 90px;
    }

    .van-field__word-limit {
      position: absolute;
      right: 0;
      bottom: 15px;
    }
  }
}
</style>
<style lang="scss" scoped>
.circleQgl_main {
  height: 100%;
  padding-bottom: 30px;

  .qcy_q_box {
    .uploader_box {
      padding: 20px 23px 20px 22px;
    }

    ::v-deep {

      .van-uploader__preview-image {
        width: 46px;
        height: 46px;
        border-radius: 50%;
      }

      .van-uploader__preview-delete {
        border-radius: 50%;
      }

      .van-uploader__preview {
        margin-right: 20px;
      }

      .van-uploader__upload {
        width: 46px;
        height: 46px;
        border-radius: 50%;
      }

      .van-icon-photograph::before {
        content: '+';
        position: relative;
        top: -5px;
      }
    }

  }

  .van_cell_read {
    span {
      font-size: 12px;
      color: #323233;
    }
  }

  .circle_type_box {
    position: relative;

    .qzTypeVal {
      position: absolute;
      top: 18.5px;
      right: 41px;
      font-size: 14px;
      color: #000000;
    }

    .p_title {
      position: absolute;
      top: 21.5px;
      right: 41px;
      font-size: 14px;
      color: rgba(109, 114, 120, 0.50);
    }

    .tips {
      position: absolute;
      right: 46px;
      top: -43px;

      .default {
        font-size: 14px;
        color: rgba(109, 114, 120, 0.50);
        position: relative;
        top: -10px;
        right: 4px;
      }

      .circle_bg_header {
        width: 32px;
        height: 32px;
        display: inline-block;
        background: #f4f4f4;
        border-radius: 50%;
      }

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }

  .p_switch {
    position: absolute;
    top: 18.5px;
    right: 20px;
  }

}
</style>
