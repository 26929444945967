<template>
  <div class="qcy_q_box">
    <ul>
      <li class="member-item" @click="goPersonalHome(item)" v-for="(item,index) in list" :key="index">
        <AvatarFrame class="avatarFrame" :user-info="item"></AvatarFrame>
        <p class="name">{{ item.name }}</p>
      </li>
      <li class="member-item" @click="goAddMember" style="justify-content: flex-start">
        <div class="flex-center-row add-box">
          <van-icon name="plus" />
        </div>
      </li>
      <li class="member-item" @click="goDeleteMember" style="justify-content: flex-start">
        <div class="flex-center-row add-box">
          <van-icon name="minus" />
        </div>
      </li>
    </ul>
    <!--    v-if="circleDetail.identity < 3"-->
    <van-cell
      class="van_cell_read"
      :title="'查看全部' + getMemberNumber() + '位成员'"
      is-link @click.prevent="van_all_per" />
  </div>
</template>

<script>
/**
 * 创建圈子和管理圈子的
 */
import AvatarFrame from '@/components/avatarFrame'

export default {
  name: "index",
  components: {
    AvatarFrame
  },
  props: {
    circleDetail: {
      type: Object,
      default: () => {
      }
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    list: {
      handler (value) {
        if (value.length > 0) {
          this.memberList = value.slice(0, 8)
        } else {
          this.memberList = []
        }
      },
      deep: true
    }
  },
  data () {
    return {
      showAllMember: false, // 成员弹框显示隐藏
      showAddMember: false, // 添加成员弹框
      userInfo: this.$store.state.user.userInfo,
      memberList: []
    }
  },
  created () {
    console.log(this.memberList)
  },
  methods: {
    getMemberNumber () {
      if (this.circleDetail && this.circleDetail.circleStatisticVO) {
        return this.circleDetail.circleStatisticVO.memberCount
      } else {
        return 0
      }
    },
    // 个人中心
    goPersonalHome (item) {
      if (item.id) {
        this.$router.push({
          name: 'userInfoDetail',
          params: {
            userId: item.userBasicVO.userId
          }
        })
      }
    },
    // 添加成员
    goAddMember () {
      this.$emit('goAddMember')
    },
    // 删除成员
    goDeleteMember () {
      this.$emit('goDeleteMember')
    },
    // 提交
    handleSubmit (value) {
      this.showAddMember = false
      this.memberList = value
    },
    // 添加成员
    addMember () {
      this.showAddMember = true
    },
    deleteMember (id) {
      this.memberList = this.memberList.filter(item => item.id !== id)
    },
    /**
     * 批量删除
     */
    handleSubmitDel (list) {
      list.forEach(item => {
        this.memberList = this.memberList.filter(memberItem => memberItem.id !== item.id)
      })
    },
    // 查看全部成员
    van_all_per () {
      // this.showAllMember = true
      this.$router.push({
        path: '/app/circle/circleManagePerson',
        query: {
          circleId: this.circleDetail.id
        }
      })
      // this.$router.push({name: "myCreateCircleAllPe"});
    },
    handleClose () {
      this.showAddMember = false
    },
    handleBack () {
      this.showAllMember = false
    },
  }
}
</script>

<style lang="scss" scoped>
.qcy_q_box {
  margin-top: 5px;
  padding: 18px 0;

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .member-item {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;

      .avatarFrame {
        width: 46px;
        height: 46px;
      }

      .name {
        margin-top: 5px;
        text-align: center;
        font-size: 12px;
        color: #6d7278;
      }
    }

    .add-box {
      width: 46px;
      height: 46px;
      background: rgba(109, 114, 120, 0.12);
      border-radius: 50%;
      color: #6d7278;
      font-size: 18px;
      font-weight: bold;
    }

  }

  .name {
    text-align: center;
    font-size: 12px;
    color: #6d7278;
  }

  .add-member {
    width: 46px;
    height: 46px;
    background: rgba(109, 114, 120, 0.12);
    border-radius: 50%;
  }

  .qcy_title {
    font-size: 14px;
    padding-left: 15px;
    font-weight: 400;
    color: #000000;
    line-height: 14px;
  }

  .uploader_box {
    padding: 18px 23px 0 22px;
  }
}
</style>
