<template>
  <van-picker
    title="圈子背景图"
    show-toolbar
    :columns="backgroundList"
    @confirm="handleConfirmBg"
    @cancel="handleCancelBg"
  >
    <div class="flex-row circle-bg-item" slot="option" slot-scope="item">
      <span class="circle-bg-color" :style="{backgroundColor: item.value}"></span>
      <span class="color">{{ item.title }}</span>
    </div>
  </van-picker>
</template>

<script>
import {getListCircleBackground} from "@/api/circle";

export default {
  name: "index",
  data() {
    return {
      backgroundList: []
    }
  },
  created() {
    this.getListCircleBackground()
  },
  methods: {
    /**
     * todo 获取圈子背景图列表
     */
    async getListCircleBackground() {
      const res = await getListCircleBackground() || []
      this.backgroundList = res
    },
    handleConfirmBg(value) {
      this.$emit('confirmBg', value)
    },
    handleCancelBg() {
      this.$emit('cancelBg')
    }
  }
}
</script>

<style lang="scss" scoped>
.circle-bg-item {
  width: 160px;

  .default {
    margin-right: 4px;
  }

  .circle-bg-color {
    width: 32px;
    height: 32px;
    display: inline-block;
    background: #f4f4f4;
    border-radius: 50%;
  }

  .color {
    margin-left: 11px;
  }
}
</style>
